import * as React from "react"

import { Container } from "react-bootstrap"

import Seo from "../components/common/seo"
import Layout from "../components/common/layout"

import About from "../components/about"


const AboutPage = () => {
  return (
    <Layout>
      <Seo title="Sobre" />
      <div className="about padding-top padding-bottom">
        <Container>
          <About />
        </Container>
      </div>
    </Layout>
  )
}

export default AboutPage
