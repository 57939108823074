import * as React from "react"
import { Link } from "gatsby"
import { Row, Col } from "react-bootstrap"

const AboutComponent = () => {
  return (
    <>
      <Row className="margin-top-sm">
        <Col md={6}>
          <div>
            <h1 className="gradient-text">Sobre</h1>
          </div>
          <div>
            <h1 className="gradient-text">a Unnit</h1>
          </div>
        </Col>
      </Row>
      <Row className="hide-about" style={{ paddingBottom: 100 }}>
        <Col md={6}>
          <p className="py-3">
            A Unnit atua como uma parceira estratégica para empresas e
            instituições que buscam otimizar recursos e melhorar sua
            produtividade, através de soluções tecnológicas projetadas para
            serem acessíveis e eficazes.
          </p>
          <Link to={`/sobre`} className="btn btn-primary">
            Saiba mais
          </Link>
        </Col>
      </Row>
      <Row>
        <Col className="hide-home pt-4 paragraph-2">
          <p>
            A Unnit atua como uma parceira estratégica para empresas e
            instituições que buscam otimizar recursos e melhorar sua
            produtividade, através de soluções tecnológicas projetadas para
            serem acessíveis e eficazes. A Unnit se posiciona como uma empresa
            integradora, capaz de aproximar fabricantes globais de parceiros
            locais, focando na necessidade de cada cliente. O negócio da Unnit é
            tecnologia da informação, mas é também entender, planejar e
            contribuir para o desenvolvimento de todos que a cercam. Nosso foco
            é garantir melhorias, seja na solução pontual ou em projetos mais
            complexos.
          </p>
          <h4>NEGÓCIO</h4>
          <p>
            Consultoria, Projetos e Fornecimento de soluções de Infraestrutura
            de Tecnologia da Informação e Comunicações (TIC).
          </p>
          <h4>MISSÃO</h4>
          <p>
            Oferecer ao mercado corporativo soluções eficazes em Tecnologia da
            Informação e Comunicação (TIC) alinhadas ao negócio do cliente,
            priorizando a mão de obra local.
          </p>
          <h4>VALORES</h4>
          <p>Agilidade + Inovação + Comprometimento + Excelência​</p>
          <h4>VISÃO</h4>
          <p>
            Ser referência no mercado do Rio Grande do Sul até 2025. Seja
            através do fornecimento de soluções eficazes no ramo da Tecnologia
            da Informação e Comunicação (TIC), como pela qualidade e pela
            inovação das soluções ofertadas.​
          </p>
        </Col>
      </Row>
    </>
  )
}

export default AboutComponent
